/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

// Slices
// import { NavbarSummaries } from '../../../components/article-previews'
import { EXTERNAL_LIKS } from '../../../configs'
import { validateURL } from '../../../utils/ValidateUrl'

function NavBar(): ReactElement {
	const [sticky, setSticky] = useState('')
	const [showSearch, setShowSearch] = useState(false)

	const history = useHistory()
	const isShowSearch = useRef<any>(null)
	isShowSearch.current = showSearch

	useEffect(() => {
		window.addEventListener('scroll', myHeader)
		return () => { window.removeEventListener('scroll', myHeader) }
	}, [])


	useEffect(() => {
		history.listen(() => {
			if (isShowSearch.current !== null && isShowSearch.current === true) {
				setShowSearch(false)
			}
		})
	}, [history])

	function myHeader() {
		const scrollTop = window.scrollY
		const stickyClass = scrollTop >= 145 ? 'navigation-bar-sticky' : ''
		setSticky(stickyClass)
	}

	const classes = `navigation-bar navigation-bar--fullwidth hidden-xs hidden-sm js-sticky-header-holder ${sticky} d-none d-lg-block`

	return (
		<div className={classes} id="main-navigation">
			<div className="container">
				<div className="navigation-bar__inner">
					<div className="navigation-wrapper navigation-bar__section text-center js-priority-nav">
						<ul id="menu-main-menu" className="navigation navigation--main navigation--inline">
							<li className="menu-item">
								<Link to={'/'} >Home</Link>
							</li>
							{/* <li className="menu-item">
								<a style={{ cursor: 'pointer' }}>The Wine Advocate</a>
								<div className="mnmd-mega-menu">
									<NavbarSummaries />
								</div>
							</li> */}
							<li className="menu-item-has-children">
								<a style={{ cursor: 'pointer' }}>Articles</a>
								<ul className="sub-menu">
									<li>
										<Link to={'/issues/latest'}>The Wine Advocate</Link>
									</li>
									<li>
										<Link to={'/more-free-stuff'}>Free to Read</Link>
									</li>
									<li>
										<Link to={'/free-publications/wine-journal'}>Wine Journal</Link>
									</li>
								</ul>
							</li>
							<li className="menu-item-has-children">
								<a style={{ cursor: 'pointer' }}>About Us</a>
								<ul className="sub-menu">
									<li>
										<Link to={'/about/reviewers'}>Our Reviewer Team</Link>
									</li>
									<li>
										<Link to={'/about/the-wine-advocate'}>About The Wine Advocate</Link>
									</li>
									<li>
										<Link to={'/about/origins-of-the-wine-advocate'}>Origins of Wine Advocate</Link>
									</li>
									<li>
										<Link to={'/about/reports'}>The Wine Advocate Reports</Link>
									</li>
									<li>
										<Link to={'/about/the-rating-system'}>RPWA Rating System</Link>
									</li>
								</ul>
							</li>
							<li className="menu-item">
								<a href={validateURL(`${EXTERNAL_LIKS.EVENTS_PAGE}`) ? `${EXTERNAL_LIKS.EVENTS_PAGE}` : ''} target='_blank' rel='noreferrer'>Events</a>
							</li>
							<li className="menu-item">
								<Link to={'/vintage-chart'} >Vintage Chart</Link>
							</li>
							<li className="menu-item-has-children">
								<a style={{ cursor: 'pointer' }}>Green Emblem</a>
								<ul className="sub-menu">
									<li>
										<Link to={'/sustainability-and-wine/green-emblem-list'}>Green Emblem Wineries</Link>
									</li>
									<li>
										<Link to={'/free-publications/green-emblem'}>Green Emblem Mission & Objectives</Link>
									</li>
									<li>
										<Link to={'/free-publications/certification'}>Certified Sustainability</Link>
									</li>
								</ul>
							</li>
							<li className="menu-item-has-children">
								<a style={{ cursor: 'pointer' }}>Resources</a>
								<ul className="sub-menu">
									<li>
										<a style={{ pointerEvents: 'none' }} href={validateURL(`${EXTERNAL_LIKS.RP_CELLAR}`) ? `${EXTERNAL_LIKS.RP_CELLAR}` : ''} target='_blank' rel='noreferrer'>RP Cellar</a>
									</li>
									<li>
										<Link to={'/editorial-calendar'} >Editorial Calendar</Link>
									</li>
									<li>
										<Link to={'/trade-partners/trade-directory'} >Trade Directory</Link>
									</li>
									<li>
										<Link to={'/marketing-materials'} >Marketing Materials</Link>
									</li>

									<li>
										<Link to={'/faq'} >FAQ</Link>
									</li>
									<li>
										<Link to={'/resources/about-wines'} >Glossary of Terms</Link>
									</li>
									{/* <li>
										<Link to={'/contact-us'} >Contact Us</Link>
									</li> */}
								</ul>
							</li>

						</ul>
					</div>
				</div>
			</div>
		</div >
	)
}

export default NavBar